@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
@import url('https://fonts.googleapis.com/css?family=Urbanist&display=swap');

* {
	box-sizing: border-box;
}

body {
	background-image: url('images/background.jpeg');
	background-size: cover;
	background-position: center center;
	display: flex;
	font-family: 'Urbanist', sans-serif;
	font-size: 140%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
	min-height: 100vh;
}

h4 {
	margin: 0 0 5px;
}

progress {
	width: 100%;
	min-height: 30px;
}

p {
	line-height: 1.5;
}

.container {
	box-shadow: 0 4px 15px -5px rgba(0, 0, 0, 0.7);
	background-color: #ddd;
	border: 2px solid #000;
	padding: 40px;
	width: 1000px;
	max-width: 100%;
	text-align: center;
}

.container .text {
	display: flex;
	flex-wrap: wrap;
}

.container input {
	padding: 12px 15px;
	font-size: 20px;
	margin: 10px 0;
	width: 100%;
	background-color: rgb(241, 241, 241);
}

.start-btn {
	border: 0;
	background-color: #000;
	color: #ddd;
	font-size: 16px;
	padding: 12px 15px;
}

.wpm {
	position: fixed;
	top: 20px;
	right: 20px;
	text-align: right;
}

.green {
	color: green;
}

.red {
	background-color: rgba(255, 0, 0, 0.5);
}

.underline {
	border-bottom: 1px solid #000;
}

.word {
	font-size: 20px;
	margin: 2px;
}

.share {
	color: #38a1f3;
}

@media screen and (max-width: 650px) {
	p {
		line-height: 1.3;
	}
	
	.container {
		margin-bottom: 50px;
		padding: 10px;
		width: 100%;
	}
	
	.wpm {
		display: none;
	}
}

.floating-btn {
	border-radius: 26.5px;
	background-color: #001F61;
	border: 1px solid #001F61;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
}

.floating-btn:hover {
	background-color: #fff;
	color: #001F61;
}

.floating-btn:focus {
	outline: none;
}

.floating-text {
	background-color: #001F61;
	border-radius: 10px 10px 0 0;
	color: #fff;
	font-family: 'Muli';
	padding: 7px 15px;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	z-index: 998;
}

.floating-text a {
	color: #FF7500;
	text-decoration: none;
}

@media screen and (max-width: 480px) {

	.social-panel-container.visible {
		transform: translateX(0px);
	}
	
	.floating-btn {
		right: 10px;
	}
}